import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IRedactionAction } from '@/dataroom/domain/vo/redaction/RedactionAction';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';
import { IRedactionEntity } from '@/Framework/UI/Organisms/DocumentViewer/plugins/RedactionPlugin/types';

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
class RedactionRepository {
  constructor(protected rpc: typeof JsonRpcDispatcherFactory, private socketClient: typeof SocketClientFactory) {
  }

  getRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<{ collection: IRedactionEntity[] }> => {
    const request = new Request('redaction.list', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  applyAllRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
      actions: IRedactionAction[],
      onFinish: () => void,
      onError: () => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const notificationId = `redaction-file-${ requestPayload.fileId }`;
    const request = new Request('redaction.apply', {
      ...requestPayload,
    });

    const subscribeReq = new Request('redaction.apply.listen', {
      notificationId,
    });

    const subscription = await this.socketClient()
      .subscribe(subscribeReq);

    subscription
      .on('redaction.apply.completed', () => {
        subscription.cancel();
        onFinish();
      })
      .on('redaction.apply.error', () => {
        subscription.cancel();
        onError();
      });

    try {
      const response = await this.rpc()
        .call<RpcSuccess>(request);

      return response.getResult();
    } catch (error) {
      subscription.cancel();
      throw error;
    }
  };

  removeAllRedaction = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<void> => {
    const request = new Request('redaction.delete_redacted', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default RedactionRepository;
